// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Header from './components/Header'
import ScrollToTop from './components/ScrollToTop';
import Footer from './components/Footer';
import About from './pages/About';
import Services from './pages/Services';
import Contact from './pages/Contact';


function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Header/>
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer/>
    </Router>
    // <h1 className="text-3xl font-bold underline text-green-600">
    //   Hello world!
    // </h1>
  );
}

export default App;
