// import React from 'react'

// const Services = () => {
//   return (
//     <div>
//        {/* <!-- Breadcrumb Section Begin --> */}
//     <section class="breadcrumb-option spad set-bg" style={{ backgroundImage: 'url(img/breadcrumb-bg.jpg)' }} >
//         <div class="container">
//             <div class="row">
//                 <div class="col-lg-12 text-center">
//                     <div class="breadcrumb__text">
//                         <h2>Our Services</h2>
//                         <div class="breadcrumb__links">
//                             <a href="./index.html">Home</a>
//                             <span>Services</span>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </section>
//     {/* <!-- Breadcrumb Section End --> */}

//     {/* <!-- Services Section Begin --> */}
//     <section className="my-10 service-area area-padding-top">
//         <div className="container">
//           <div className="row">
//             {/* Service 1: Preventive Care */}
//             <div className="col-md-6 col-lg-4 mb-4">
//               <div className="card single-service text-center h-100">
//                 <div className="card-body">
//                   <div className="service-icon mb-3">
//                     <i className="flaticon-brushing-teeth"></i>
//                   </div>
//                   <h4 className="card-title">Preventive Care</h4>
//                   <p className="card-text">
//                     Routine checkups, cleanings, and exams to maintain your oral health and prevent dental issues.
//                   </p>
//                 </div>

//               </div>
//             </div>

//             {/* Service 2: Restorative Dentistry */}
//             <div className="col-md-6 col-lg-4 mb-4">
//               <div className="card single-service text-center h-100">
//                 <div className="card-body">
//                   <div className="service-icon mb-3">
//                     <i className="flaticon-cavity"></i>
//                   </div>
//                   <h4 className="card-title">Restorative Dentistry</h4>
//                   <p className="card-text">
//                     Treatments such as fillings, crowns, bridges, and implants to restore the function and appearance of your teeth.
//                   </p>
//                 </div>
//                 <div className="card-footer bg-transparent border-top-0">

//                 </div>
//               </div>
//             </div>

//             {/* Service 3: Cosmetic Dentistry */}
//             <div className="col-md-6 col-lg-4 mb-4">
//               <div className="card single-service text-center h-100">
//                 <div className="card-body">
//                   <div className="service-icon mb-3">
//                     <i className="flaticon-teeth"></i>
//                   </div>
//                   <h4 className="card-title">Cosmetic Dentistry</h4>
//                   <p className="card-text">
//                     Enhance your smile with teeth whitening, veneers, bonding, and other cosmetic procedures tailored to your needs.
//                   </p>
//                 </div>
//                 <div className="card-footer bg-transparent border-top-0">

//                 </div>
//               </div>
//             </div>

//             {/* Service 4: Orthodontics */}
//             <div className="col-md-6 col-lg-4 mb-4">
//               <div className="card single-service text-center h-100">
//                 <div className="card-body">
//                   <div className="service-icon mb-3">
//                     <i className="flaticon-braces"></i>
//                   </div>
//                   <h4 className="card-title">Orthodontics</h4>
//                   <p className="card-text">
//                     Straighten your teeth and improve your bite with braces, Invisalign, and other orthodontic solutions.
//                   </p>
//                 </div>
//                 <div className="card-footer bg-transparent border-top-0">

//                 </div>
//               </div>
//             </div>

//             {/* Service 5: Emergency Care */}
//             <div className="col-md-6 col-lg-4 mb-4">
//               <div className="card single-service text-center h-100">
//                 <div className="card-body">
//                   <div className="service-icon mb-3">
//                     <i className="flaticon-emergency"></i>
//                   </div>
//                   <h4 className="card-title">Emergency Care</h4>
//                   <p className="card-text">
//                     Prompt and effective treatment for dental emergencies such as toothaches, broken teeth, and more.
//                   </p>
//                 </div>
//                 <div className="card-footer bg-transparent border-top-0">

//                 </div>
//               </div>
//             </div>

//             {/* Service 6: Pediatric Dentistry */}
//             <div className="col-md-6 col-lg-4 mb-4">
//               <div className="card single-service text-center h-100">
//                 <div className="card-body">
//                   <div className="service-icon mb-3">
//                     <i className="flaticon-child"></i>
//                   </div>
//                   <h4 className="card-title">Pediatric Dentistry</h4>
//                   <p className="card-text">
//                     Specialized dental care for children to ensure healthy smiles from an early age.
//                   </p>
//                 </div>
//                 <div className="card-footer bg-transparent border-top-0">

//                 </div>
//               </div>
//             </div>

//           </div>
//         </div>
//       </section>
//     {/* <!-- Services Section End --> */}
//     </div>
//   )
// }

// export default Services















import React from 'react';

const Services = () => {
  return (
    <div className="bg-gray-50 min-h-screen">
      {/* Header Section */}
      <div className="py-10 text-center">
        <h1 className="text-4xl text-black font-semibold hover:cursor-pointer hover:text-gray-800 transition duration-200">
          Our Services
        </h1>
      </div>

      {/* Services Section */}
      <section className="py-10">
        <div className="container mx-auto px-4">
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {/* Map services dynamically */}
            {servicesData.map((service, index) => (
              <div
                key={index}
                className="bg-white shadow-lg rounded-lg p-6 hover:shadow-2xl transition duration-300"
              >
                <div className="flex flex-col items-center">
                  <div className="bg-blue-100 p-4 rounded-full mb-4">
                    <i
                      className={`${service.icon} text-blue-600 text-4xl`}
                      aria-hidden="true"
                    ></i>
                  </div>
                  <h4 className="text-xl font-semibold text-gray-800 mb-2">
                    {service.title}
                  </h4>
                  <p className="text-gray-600 text-center mb-4">
                    {service.description}
                  </p>
                  <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200">
                    Learn More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

// Service data array for easy maintenance
const servicesData = [
  {
    title: 'Preventive Care',
    description:
      'Routine checkups, cleanings, and exams to maintain your oral health and prevent dental issues.',
    icon: 'flaticon-brushing-teeth',
  },
  {
    title: 'Restorative Dentistry',
    description:
      'Treatments such as fillings, crowns, bridges, and implants to restore the function and appearance of your teeth.',
    icon: 'flaticon-cavity',
  },
  {
    title: 'Cosmetic Dentistry',
    description:
      'Enhance your smile with teeth whitening, veneers, bonding, and other cosmetic procedures tailored to your needs.',
    icon: 'flaticon-teeth',
  },
  {
    title: 'Orthodontics',
    description:
      'Straighten your teeth and improve your bite with braces, Invisalign, and other orthodontic solutions.',
    icon: 'flaticon-braces',
  },
  {
    title: 'Emergency Care',
    description:
      'Prompt and effective treatment for dental emergencies such as toothaches, broken teeth, and more.',
    icon: 'flaticon-emergency',
  },
  {
    title: 'Pediatric Dentistry',
    description:
      'Specialized dental care for children to ensure healthy smiles from an early age.',
    icon: 'flaticon-child',
  },
  {
    title: 'Smile Makeovers',
    description:
      'Comprehensive smile design solutions to enhance your appearance and confidence.',
    icon: 'flaticon-smile',
  },
  {
    title: 'Sedation Dentistry',
    description:
      'Comfortable dental care with sedation options for stress-free experiences.',
    icon: 'flaticon-sleep',
  },
  {
    title: 'Oral Surgery',
    description:
      'Professional care for complex cases, including wisdom teeth extraction and more.',
    icon: 'flaticon-surgery',
  },
];

export default Services;


