import React from 'react'

const Footer = () => {
  return (
    <>
       {/* <!-- Footer Section Begin --> */}
       <footer className="footer">
                    <div className="footer__top">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="footer__logo">
                                        <a href="#"><img src="img/logo.png" alt="" height={70}/></a>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-8">
                                    <div className="footer__newslatter">
                                        <form action="#">
                                            <input type="text" placeholder="Email" />
                                            <button type="submit" className="site-btn">Subscribe</button>
                                        </form>
                                    </div>
                                </div> */}
                                <div className="col-lg-6 col-md-12">
                                    <div className="footer__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="footer__widget">
                                    <h5>Company</h5>
                                    <ul>
                                        <li><a href="#">About Us</a></li>
                                        <li><a href="#">Departments</a></li>
                                        <li><a href="#">Find a Doctor</a></li>
                                        <li><a href="#">FAQ</a></li>
                                        <li><a href="#">News</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-3 col-sm-6">
                                <div className="footer__widget">
                                    <h5>Quick links</h5>
                                    <ul>
                                        <li><a href="#">Facial Fillers</a></li>
                                        <li><a href="#">Breast Surgery</a></li>
                                        <li><a href="#">Body Lifts</a></li>
                                        <li><a href="#">Face & Neck</a></li>
                                        <li><a href="#">Fat Reduction</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="footer__address">
                                    <h5>Contact Us</h5>
                                    <ul>
                                        <li><i className="fa fa-map-marker"></i>Plot No. 12/A, Mansha Square, Near S-Mart, Jafar Nagar, Nagpur - 440013</li>
                                        <li><i className="fa fa-phone"></i>+91 72767 09059</li>
                                        <li><i className="fa fa-envelope"></i>support@ivorysmilesdental.in</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-6">
                                <div className="footer__map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48158.305462977965!2d-74.13283844036356!3d41.02757295168286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2e440473470d7%3A0xcaf503ca2ee57958!2sSaddle%20River%2C%20NJ%2007458%2C%20USA!5e0!3m2!1sen!2sbd!4v1575917275626!5m2!1sen!2sbd"
                                        height="190" style={{ border: "0" }} allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer__copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <p className="text-center text-md-end text-xl-start">
                                        All Rights Reserved
                                    </p>
                                </div>
                                <div className="col-lg-5">
                                    <ul>
                                        <li>All Rights Reserved</li>
                                        <li>Terms & Use</li>
                                        <li>Privacy Policy</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
    </>
  )
}

export default Footer
