import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Function to toggle mobile menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div>
      {/* <!-- Offcanvas Menu Begin --> */}
      <div className={`offcanvas-menu-overlay ${isMenuOpen ? 'active' : ''}`} onClick={toggleMenu}></div>
      <div className={`offcanvas-menu-wrapper ${isMenuOpen ? 'active' : ''}`}>
        <div className="offcanvas__logo">
          <NavLink to="/"><img src="/img/logo.png" alt="Logo" className="logo-img" /></NavLink>
        </div>
        <nav>
  <ul style={{
    listStyle: 'none',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'space-around',
    flexDirection:  'column' , // Stack items vertically on mobile

    alignItems: 'left',
    flexWrap: 'wrap'
  }}>
    <li style={{ margin: '10px 0' }}>
      <NavLink 
        to="/" 
        exact 
        activeClassName="active"
        style={{
          textDecoration: 'none',
          color: '#333',
          fontSize: '16px',
          fontWeight: '600',
          textTransform: 'uppercase',
          padding: '10px 20px',
          transition: 'color 0.3s ease, background-color 0.3s ease'
        }}
        activeStyle={{
          color: '#fff',
          backgroundColor: '#ff325d',
          borderRadius: '5px',
          padding: '10px 20px'
        }}
      >
        Home
      </NavLink>
    </li>
    <li style={{ margin: '10px 0' }}>
      <NavLink 
        to="/about" 
        activeClassName="active"
        style={{
          textDecoration: 'none',
          color: '#333',
          fontSize: '16px',
          fontWeight: '600',
          textTransform: 'uppercase',
          padding: '10px 20px',
          transition: 'color 0.3s ease, background-color 0.3s ease'
        }}
        activeStyle={{
          color: '#fff',
          backgroundColor: '#ff325d',
          borderRadius: '5px',
          padding: '10px 20px'
        }}
      >
        About
      </NavLink>
    </li>
    <li style={{ margin: '10px 0' }}>
      <NavLink 
        to="/services" 
        activeClassName="active"
        style={{
          textDecoration: 'none',
          color: '#333',
          fontSize: '16px',
          fontWeight: '600',
          textTransform: 'uppercase',
          padding: '10px 20px',
          transition: 'color 0.3s ease, background-color 0.3s ease'
        }}
        activeStyle={{
          color: '#fff',
          backgroundColor: '#ff325d',
          borderRadius: '5px',
          padding: '10px 20px'
        }}
      >
        Services
      </NavLink>
    </li>
    <li style={{ margin: '10px 0' }}>
      <NavLink 
        to="/contact" 
        activeClassName="active"
        style={{
          textDecoration: 'none',
          color: '#333',
          fontSize: '16px',
          fontWeight: '600',
          textTransform: 'uppercase',
          padding: '10px 20px',
          transition: 'color 0.3s ease, background-color 0.3s ease'
        }}
        activeStyle={{
          color: '#fff',
          backgroundColor: '#ff325d',
          borderRadius: '5px',
          padding: '10px 20px'
        }}
      >
        Contact
      </NavLink>
    </li>
  </ul>
</nav>

        <div id="mobile-menu-wrap"></div>
        <div className="offcanvas__btn">
          <a href="#" className="primary-btn">Appointment</a>
        </div>
        <ul className="offcanvas__widget">
          <li><i className="fa fa-phone"></i>+91 72767 09059</li>
          <li><i className="fa fa-map-marker"></i>Plot No. 12/A, Mansha Square, Near S-Mart, Jafar Nagar, Nagpur - 440013</li>
        </ul>
        <div className="offcanvas__social">
          <a href="#"><i className="fa fa-facebook"></i></a>
          <a href="#"><i className="fa fa-twitter"></i></a>
          <a href="#"><i className="fa fa-instagram"></i></a>
          <a href="#"><i className="fa fa-dribbble"></i></a>
        </div>
      </div>
      {/* <!-- Offcanvas Menu End --> */}

      {/* <!-- Header Section Begin --> */}
      <header className="header">
        <div className="header__top">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <ul className="header__top__left">
                  <li><i className="fa fa-phone"></i>+91 72767 09059</li>
                  <li><i className="fa fa-map-marker"></i>Plot No. 12/A, Mansha Square, Near S-Mart, Jafar Nagar, Nagpur - 440013</li>
                </ul>
              </div>
              
              <div className="col-lg-4">
                <div className="header__top__right">
                  <a href="#"><i className="fa fa-facebook"></i></a>
                  <a href="#"><i className="fa fa-twitter"></i></a>
                  <a href="#"><i className="fa fa-instagram"></i></a>
                  <a href="#"><i className="fa fa-dribbble"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-2">
              <div className="header__logo">
                <NavLink to="/"><img src="/img/logo.png" alt="Logo" height={60} className="logo-img" /></NavLink>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="header__menu__option">
                <nav className="header__menu">
                  <ul>
                    <li>
                      <NavLink to="/" exact activeClassName="active">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about" activeClassName="active">About</NavLink>
                    </li>
                    <li>
                      <NavLink to="/services" activeClassName="active">Services</NavLink>
                    </li>
                    <li>
                      <NavLink to="/contact" activeClassName="active">Contact</NavLink>
                    </li>
                  </ul>
                </nav>
                <div className="header__btn">
                  <a href="#" className="primary-btn">Appointment</a>
                </div>
              </div>
            </div>
          </div>
          {/* Hamburger Menu Icon */}
          <div className="canvas__open" onClick={toggleMenu}>
            <i className="fa fa-bars"></i>
          </div>
        </div>
      </header>
      {/* <!-- Header Section End --> */}
    </div>
  );
}

export default Header;
