import React, { useState } from 'react'
import Header from '../components/Header';
import Services from './Services'

const Home = () => {
    // State for form inputs
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [date, setDate] = useState('');
    const [serviceType, setServiceType] = useState('');

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle the form submission logic here
        console.log({ name, email, date, serviceType });
    };
    return (
   
            <>
                {/* <!-- Page Preloder --> */}
                {/* <div id="preloder">
        <div className="loader"></div>
    </div> */}

               

                {/* <!-- Hero Section Begin --> */}
                <section className="hero spad set-bg" style={{ backgroundImage: 'url(/img/hero-bg.png)' }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="hero__text">
                                    <span>Welcome to Ivory Smiles Dental Clinic</span>
                                    <h2>At Ivory Smiles Dental Clinic</h2>
                                    <p>We believe that a beautiful smile is not just a reflection of health, but also of confidence and well-being. Our clinic, located in the heart of Nagpur, is committed to providing top-notch dental care in a warm and friendly environment. Whether you're looking for routine check-ups, cosmetic treatments, or advanced dental procedures, our expert team is here to cater to all your oral health needs.</p>
                                    <a href="#" className="primary-btn normal-btn">Book an Appointment</a>
                                </div>  
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Hero Section End --> */}

                {/* <!-- Consultation Section Begin --> */}
                {/* <section class="consultation">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="consultation__form"> 
                                    <div class="section-title">
                                        <span>REQUEST FOR YOUR</span>
                                        <h2>Appointment</h2>
                                    </div> 
                                    <form action="#">
                                        <input type="text" placeholder="Name" />
                                        <input type="text" placeholder="Email" />
                                        <div class="datepicker__item">
                                            <input type="text" placeholder="Date" class="datepicker" />
                                            <i class="fa fa-calendar"></i>
                                        </div>

                                         <div>
                                            <select >
                                                <option value="">Type of service</option>
                                                <option value="">Advanced equipment</option>
                                                <option value="">Qualified doctors</option>
                                                <option value="">Certified services</option>
                                                <option value="">Emergency care</option>
                                            </select>  
                                        </div>  
                                        <button type="submit" class="site-btn">Book appoitment</button>
                                    </form> 
                                </div>
                            </div> 
                            <div class="col-lg-8">
                                <div class="consultation__text">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="consultation__text__item">
                                                <div class="section-title">
                                                    <span>Welcome to Ivory Smiles
                                                    </span>
                                                    <h2>Discover Exceptional  <b>Care</b></h2>
                                                </div>
                                                <p>With over 13 years of experience in cosmetic dentistry, Ivory Smiles is dedicated to providing personalized and transformative dental care. Our team of expert dentists combines advanced techniques with a deep understanding of aesthetics to craft beautiful, confident smiles.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="consultation__video set-bg" style={{ backgroundImage: 'url(img/consultation-video.jpg)' }} >
                                                <a href="https://www.youtube.com/watch?v=PXsuI67s2AA" class="play-btn video-popup"><i class="fa fa-play"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>  */}

                 <section className="consultation py-12 bg-gray-50">
  <div className="container mx-auto px-6">
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
      {/* Appointment Form */}
      <div className="bg-white shadow-lg rounded-lg p-8">
        <div className="mb-6 text-center">
          <span className="text-teal-600 text-sm uppercase font-bold">Request for Your</span>
          <h2 className="text-2xl font-extrabold text-gray-800 mt-2">Appointment</h2>
        </div>
        <form action="#">
          <input
            type="text"
            placeholder="Name"
            className="w-full mb-4 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
          />
          <input
            type="email"
            placeholder="Email"
            className="w-full mb-4 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
          />
          <div className="relative mb-4">
            <input
              type="text"
              placeholder="Date"
              className="w-full p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
            />
            <i className="fa fa-calendar absolute right-3 top-3 text-gray-400"></i>
          </div>
          <select
            className="w-full mb-6 p-3 border rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-600"
          >
            <option value="">Select Type of Service</option>
            <option value="Advanced equipment">Advanced Equipment</option>
            <option value="Qualified doctors">Qualified Doctors</option>
            <option value="Certified services">Certified Services</option>
            <option value="Emergency care">Emergency Care</option>
          </select>
          <button
            type="submit"
            className="w-full bg-teal-600 text-white py-3 rounded-lg hover:bg-teal-700 transition duration-300"
          >
            Book Appointment
          </button>
        </form>
      </div>

      {/* Informative Content */}
      <div className="flex flex-col justify-center space-y-8">
        {/* Welcome Message */}
        <div>
          <span className="text-teal-600 text-sm uppercase font-bold">Welcome to Ivory Smiles</span>
          <h2 className="text-3xl font-extrabold text-gray-800 mt-2">
            Discover Exceptional <span className="text-teal-600">Care</span>
          </h2>
          <p className="mt-4 text-gray-600">
            With over 13 years of experience in cosmetic dentistry, Ivory Smiles is dedicated to providing personalized and transformative dental care. Our team of expert dentists combines advanced techniques with a deep understanding of aesthetics to craft beautiful, confident smiles.
          </p>
        </div>

        {/* Video Section */}
        <div className="relative group">
          <div
            className="rounded-lg bg-cover bg-center h-64"
            style={{ backgroundImage: `url('img/consultation-video.jpg')` }}
          >
            <a
              href="https://www.youtube.com/watch?v=PXsuI67s2AA"
              className="absolute inset-0 flex items-center justify-center text-teal-600 bg-black bg-opacity-50 rounded-lg opacity-0 group-hover:opacity-100 transition duration-300"
            >
              <i className="fa fa-play text-4xl"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
                </section>



                {/* <!-- Consultation Section End --> */}

                {/* <!-- Chooseus Section Begin --> */}
                {/* <section className="chooseus spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="section-title">
                                    <span>Why choose us?</span>
                                    <h2>Offer for you</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="chooseus__item">
                                    <img src="img/icons/ci-1.png" alt="" />
                                    <h5>Advanced equipment</h5>
                                    <p>We use the latest state-of-the-art dental technology to ensure precise diagnostics and effective treatments, making your visit comfortable and efficient.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="chooseus__item">
                                    <img src="img/icons/ci-2.png" alt="" />
                                    <h5>Qualified doctors</h5>
                                    <p>Our team of experienced and skilled dental professionals are dedicated to providing the highest level of care, tailoring treatments to your specific needs.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="chooseus__item">
                                    <img src="img/icons/ci-3.png" alt="" />
                                    <h5>Certified services</h5>
                                    <p>We adhere to the highest standards in dental care, offering services backed by certifications and a commitment to quality and safety for all our patients.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="chooseus__item">
                                    <img src="img/icons/injection.png" alt="" width={60}/>
                                    <h5>Emergency care</h5>
                                    <p>Dental emergencies can happen at any time. We're here to provide fast, reliable emergency dental care whenever you need it most.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="chooseus__item">
                                    <img src="img/icons/pay.png" alt="" width={60}/>
                                    <h5>Affordable Treatment Options
                                    </h5>
                                    <p>We offer cost-effective dental solutions without compromising on quality.

</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="chooseus__item">
                                    <img src="img/icons/patient.png" alt="" width={60}/>
                                    <h5>Patient-Centered Approach
                                    </h5>
                                    <p>From the moment you step into our clinic, your comfort and satisfaction are our top priority.

</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="py-12 bg-gray-50">
  <div className="container mx-auto px-4">
    {/* Header Section */}
    <div className="text-center mb-12">
      <div className="text-gray-600 text-lg uppercase font-semibold">Why choose us?</div>
      <h2 className="text-3xl font-bold text-gray-800 mt-2">Offer for you</h2>
    </div>

    {/* Cards Section */}
    <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
      {/* Card Items */}
      {chooseUsData.map((item, index) => (
        <div
          key={index}
          className="bg-white rounded-lg shadow-lg p-6 hover:shadow-2xl transition duration-300"
        >
          <div className="flex flex-col items-center">
            {/* Icon/Image */}
            <img
              src={item.icon}
              alt={item.title}
              className="w-16 h-16 mb-4"
            />
            {/* Title */}
            <h5 className="text-xl font-semibold text-gray-800 mb-2">{item.title}</h5>
            {/* Description */}
            <p className="text-gray-600 text-center">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
                </section>

                {/* <!-- Chooseus Section End --> */}

                {/* <!-- Services Section Begin --> */}
                 {/* <section className="services spad set-bg" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5)), url(img/services.jpeg)`,  backgroundSize: 'cover',
    backgroundPosition: 'center',}} >
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-6">
                                <div className="section-title">
                                    <span>Our services</span>
                                    <h2>Offer for you</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="services__btn">
                                    <a href="#" className="primary-btn">Contact us</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="services__item">
                                    <div className="services__item__icon">
                                        <img src="img/services/services.png" alt="" width={70}/>

                                    </div>
                                    <div className="services__item__text">
                                        <h5>Cosmetic Dentistry</h5>
                                        <p>Teeth whitening, veneers, and smile makeovers to enhance your natural beauty.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="services__item">
                                    <div className="services__item__icon">
                                    <img src="img/services/treatment.png" alt="" width={70}/>
                                    </div>
                                    <div className="services__item__text">
                                        <h5>Orthodontics</h5>
                                        <p>Braces, Invisalign, and other orthodontic treatments for a straight and confident smile.


                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="services__item">
                                    <div className="services__item__icon">
                                    <img src="img/services/dental-implant.png" alt="" width={70}/>
                                    </div>
                                    <div className="services__item__text">
                                        <h5>Implants and Restorations
                                        </h5>
                                        <p>High-quality dental implants and restorations to restore missing teeth and improve functionality.


                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="services__item">
                                    <div className="services__item__icon">
                                    <img src="img/services/checkup.png" alt="" width={70}/>
                                    </div>
                                    <div className="services__item__text">
                                        <h5>Emergency Dental Care
                                        </h5>
                                        <p>We are here for you during dental emergencies, offering quick & effective care.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>  */}
                <section
                    className="services spad set-bg"
                    style={{
                        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5)), url(img/services.jpeg)`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                    >
                    <div className="container">

                        {/* <Services /> */}
                        
                        <div className="row d-flex flex-wrap">
                        {/* Cosmetic Dentistry */}
                        <div className="col-lg-6 col-md-6 d-flex">
                            <div className="services__item flex-fill">
                            <div className="services__item__icon">
                                <img src="img/services/services.png" alt="" width={70} />
                            </div>
                            <div className="services__item__text">
                                <h5>Cosmetic Dentistry</h5>
                                <p>Enhance your smile with treatments like teeth whitening, veneers, and complete smile makeovers designed to boost your natural beauty.</p>
                            </div>
                            </div>
                        </div>

                        {/* Orthodontics */}
                        <div className="col-lg-6 col-md-6 d-flex">
                            <div className="services__item flex-fill">
                            <div className="services__item__icon">
                                <img src="img/services/treatment.png" alt="" width={70} />
                            </div>
                            <div className="services__item__text">
                                <h5>Orthodontics</h5>
                                <p>
                                Achieve a straight and confident smile with cutting-edge orthodontic solutions such as <strong>Invisalign</strong>, <strong>Invisible Braces and Aligners</strong>, and <strong>Clear Braces</strong>. Our personalized approach focuses on not only <strong>teeth straightening</strong> but also fostering <strong>facial bone and jaw development</strong> for optimal dento-facial harmony.
                                </p>
                            </div>
                            </div>
                        </div>

                        {/* Implants and Restorations */}
                        <div className="col-lg-6 col-md-6 d-flex">
                            <div className="services__item flex-fill">
                            <div className="services__item__icon">
                                <img src="img/services/dental-implant.png" alt="" width={70} />
                            </div>
                            <div className="services__item__text">
                                <h5>Implants and Restorations</h5>
                                <p>Restore missing teeth with high-quality dental implants and restorations, ensuring functionality, durability, and a seamless smile.</p>
                            </div>
                            </div>
                        </div>

                        {/* Emergency Dental Care */}
                        <div className="col-lg-6 col-md-6 d-flex">
                            <div className="services__item flex-fill">
                            <div className="services__item__icon">
                                <img src="img/services/checkup.png" alt="" width={70} />
                            </div>
                            <div className="services__item__text">
                                <h5>Emergency Dental Care</h5>
                                <p>Quick and effective care during dental emergencies to alleviate pain and restore oral health promptly and professionally.</p>
                            </div>
                            </div>
                        </div>

                        {/* Advanced Dento-Facial Orthopedics */}
                        {/* <div className="col-lg-12 d-flex">
                            <div className="services__item flex-fill mt-4">
                            <div className="services__item__icon">
                                <img src="img/services/orthopedics.png" alt="" width={70} />
                            </div>
                             <div className="services__item__text">
                                <h5>Dento-Facial Orthopedics</h5>
                                <p>
                                Our expertise in <strong>Dento-Facial Orthopedics</strong> focuses on <strong>facial growth</strong> and <strong>jaw development</strong>, ensuring optimal alignment of teeth, jaws, and facial aesthetics. With treatments designed for children and adults, we guide the harmonious development of your <strong>face and jaw</strong> structure, contributing to overall oral health and confidence.
                                </p>
                            </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </section>



                {/* <!-- Services Section End --> */}

                {/* <!-- Team Section Begin --> */}
                {/* <section className="team spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <div className="section-title">
                                    <span>Our Team</span>
                                    <h2>Our Expert Doctors</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="team__item">
                                    <img src="img/team/team-1.jpg" alt="" />
                                    <h5>Caroline Grant</h5>
                                    <span>Plastic surgeon</span>
                                    <div className="team__item__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="team__item">
                                    <img src="img/team/team-2.jpg" alt="" />
                                    <h5>Dr. Maria Angel</h5>
                                    <span>Plastic surgeon</span>
                                    <div className="team__item__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="team__item">
                                    <img src="img/team/team-3.jpg" alt="" />
                                    <h5>Nathan Mullins</h5>
                                    <span>Plastic surgeon</span>
                                    <div className="team__item__social">
                                        <a href="#"><i className="fa fa-facebook"></i></a>
                                        <a href="#"><i className="fa fa-twitter"></i></a>
                                        <a href="#"><i className="fa fa-instagram"></i></a>
                                        <a href="#"><i className="fa fa-dribbble"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                    <section className="py-12 bg-gray-50">
                    <div className="container mx-auto px-4">
                {/* Header */}
                <div className="text-center mb-12">
                <div className="text-gray-600 text-lg uppercase font-semibold">Our Team</div>
                <h2 className="text-3xl font-bold text-gray-800 mt-2">Our Expert Doctors</h2>
                </div>

                {/* Team Cards */}
                <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                {teamData.map((teamMember, index) => (
                    <div
                    key={index}
                    className="bg-white rounded-lg shadow-lg overflow-hidden hover:shadow-2xl transition duration-300 cursor-pointer"
                    >
                    {/* Image */}
                    <div className="relative">
                        <img
                        src={teamMember.image}
                        alt={teamMember.name}
                        className="w-full h-64 object-contain"
                        />
                        {/* Hover Overlay */}
                        <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 hover:opacity-100 transition duration-300">
                        <div className="flex space-x-4">
                            {teamMember.socials.map((social, idx) => (
                            <a
                                key={idx}
                                href={social.link}
                                className="text-white text-lg hover:text-gray-300"
                            >
                                <i className={`fa ${social.icon}`}></i>
                            </a>
                            ))}
                        </div>
                        </div>
                    </div>
                    {/* Content */}
                    <div className="p-6 text-center">
                        <h5 className="text-xl font-semibold text-gray-800">{teamMember.name}</h5>
                        <span className="text-gray-600 text-sm">{teamMember.role}</span>
                    </div>
                    </div>
                ))}
                </div>
            </div>
                    </section>

                {/* <!-- Team Section End --> */}

                {/* <!-- Gallery Begin --> */}
                {/* <div className="gallery">
                    <div className="gallery__container">
                        <div className="grid-sizer"></div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-1.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-2.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-3.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item gc__item__large set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-4.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-5.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-6.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                        <div className="gc__item set-bg" style={{ backgroundImage: 'url(img/gallery/ivory-interiar.jpeg)' }}>
                            <a href="img/gallery/gallery-7.jpg" className="image-popup"><i className="fa fa-search-plus"></i></a>
                        </div>
                    </div>
                </div> */}
                <div className="gallery py-10 bg-gray-100">
  <div className="gallery__container max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    {/* Gallery Grid */}
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
      {/* Gallery Item */}
      <div
        className="relative group bg-cover bg-center h-64 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage: "url(img/gallery/ivory-interiar.jpeg)",
        }}
      >
        <a
          href="img/gallery/gallery-1.jpg"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300"
        >
          <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100"></i>
        </a>
      </div>

      {/* Repeated Items */}
      <div
        className="relative group bg-cover bg-center h-64 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage: "url(img/gallery/ivory-interiar.jpeg)",
        }}
      >
        <a
          href="img/gallery/gallery-2.jpg"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300"
        >
          <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100"></i>
        </a>
      </div>

      <div
        className="relative group bg-cover bg-center h-64 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage: "url(img/gallery/ivory-interiar.jpeg)",
        }}
      >
        <a
          href="img/gallery/gallery-3.jpg"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300"
        >
          <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100"></i>
        </a>
      </div>

      {/* Larger Gallery Item */}
      <div
        className="relative group bg-cover bg-center h-96 md:col-span-2 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage: "url(img/gallery/ivory-interiar.jpeg)",
        }}
      >
        <a
          href="img/gallery/gallery-4.jpg"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300"
        >
          <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100"></i>
        </a>
      </div>

      {/* Other Items */}
      <div
        className="relative group bg-cover bg-center h-64 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage: "url(img/gallery/ivory-interiar.jpeg)",
        }}
      >
        <a
          href="img/gallery/gallery-5.jpg"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300"
        >
          <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100"></i>
        </a>
      </div>

      <div
        className="relative group bg-cover bg-center h-64 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage: "url(img/gallery/ivory-interiar.jpeg)",
        }}
      >
        <a
          href="img/gallery/gallery-6.jpg"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300"
        >
          <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100"></i>
        </a>
      </div>

      <div
        className="relative group bg-cover bg-center h-64 rounded-lg shadow-lg overflow-hidden"
        style={{
          backgroundImage: "url(img/gallery/ivory-interiar.jpeg)",
        }}
      >
        <a
          href="img/gallery/gallery-7.jpg"
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-0 group-hover:bg-opacity-50 transition duration-300"
        >
          <i className="fa fa-search-plus text-white text-2xl opacity-0 group-hover:opacity-100"></i>
        </a>
      </div>
    </div>
  </div>
</div>

                {/* <!-- Gallery End --> */}

                {/* <!-- Latest News Begin --> */}
                <section className="latest spad">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-sm-6">
                                <div className="section-title">
                                    <span>Our News</span>
                                    <h2>Skin care tips</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <div className="latest__btn">
                                    <a href="#" className="primary-btn">View all news</a>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="latest__item">
                                    <h5><a href="#">Here’s how you can get a natural glow this party season</a></h5>
                                    <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <ul>
                                        <li><img src="img/blog/blog-author.jpg" alt="" /> John Doe</li>
                                        <li>Dec 06, 2019</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="latest__item">
                                    <h5><a href="#">Get better skin with these top 10 tips for skin care</a></h5>
                                    <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <ul>
                                        <li><img src="img/blog/blog-author.jpg" alt="" /> John Doe</li>
                                        <li>Dec 06, 2019</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="latest__item">
                                    <h5><a href="#">8 Ways to Save Your Skin if You Exercise Outside This Winter</a></h5>
                                    <p>Lorem ipsum, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                    <ul>
                                        <li><img src="img/blog/blog-author.jpg" alt="" /> John Doe</li>
                                        <li>Dec 06, 2019</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- Latest News End --> */}

               


            </>
    )
}

const chooseUsData = [
    {
      title: 'Advanced Equipment',
      description:
        'We use the latest state-of-the-art dental technology to ensure precise diagnostics and effective treatments, making your visit comfortable and efficient.',
      icon: 'img/icons/ci-1.png',
    },
    {
      title: 'Qualified Doctors',
      description:
        'Our team of experienced and skilled dental professionals are dedicated to providing the highest level of care, tailoring treatments to your specific needs.',
      icon: 'img/icons/ci-2.png',
    },
    {
      title: 'Certified Services',
      description:
        'We adhere to the highest standards in dental care, offering services backed by certifications and a commitment to quality and safety for all our patients.',
      icon: 'img/icons/ci-3.png',
    },
    {
      title: 'Emergency Care',
      description:
        'Dental emergencies can happen at any time. We\'re here to provide fast, reliable emergency dental care whenever you need it most.',
      icon: 'img/icons/injection.png',
    },
    {
      title: 'Affordable Treatment Options',
      description:
        'We offer cost-effective dental solutions without compromising on quality.',
      icon: 'img/icons/pay.png',
    },
    {
      title: 'Patient-Centered Approach',
      description:
        'From the moment you step into our clinic, your comfort and satisfaction are our top priority.',
      icon: 'img/icons/patient.png',
    },
  ];


  const teamData = [
    {
      name: "Caroline Grant",
      role: "Plastic Surgeon",
      image: "img/team/team-1.jpg",
      socials: [
        { icon: "fa-facebook", link: "#" },
        { icon: "fa-twitter", link: "#" },
        { icon: "fa-instagram", link: "#" },
        { icon: "fa-dribbble", link: "#" },
      ],
    },
    {
      name: "Dr. Maria Angel",
      role: "Plastic Surgeon",
      image: "img/team/team-2.jpg",
      socials: [
        { icon: "fa-facebook", link: "#" },
        { icon: "fa-twitter", link: "#" },
        { icon: "fa-instagram", link: "#" },
        { icon: "fa-dribbble", link: "#" },
      ],
    },
    {
      name: "Nathan Mullins",
      role: "Plastic Surgeon",
      image: "img/team/team-3.jpg",
      socials: [
        { icon: "fa-facebook", link: "#" },
        { icon: "fa-twitter", link: "#" },
        { icon: "fa-instagram", link: "#" },
        { icon: "fa-dribbble", link: "#" },
      ],
    },
  ];
  
  

export default Home
